exports.components = {
  "component---src-pages-404-index-jsx": () => import("./../../../src/pages/404/index.jsx" /* webpackChunkName: "component---src-pages-404-index-jsx" */),
  "component---src-pages-aboutus-01-index-jsx": () => import("./../../../src/pages/aboutus01/index.jsx" /* webpackChunkName: "component---src-pages-aboutus-01-index-jsx" */),
  "component---src-pages-aboutus-02-index-jsx": () => import("./../../../src/pages/aboutus02/index.jsx" /* webpackChunkName: "component---src-pages-aboutus-02-index-jsx" */),
  "component---src-pages-business-01-index-jsx": () => import("./../../../src/pages/business01/index.jsx" /* webpackChunkName: "component---src-pages-business-01-index-jsx" */),
  "component---src-pages-business-02-index-jsx": () => import("./../../../src/pages/business02/index.jsx" /* webpackChunkName: "component---src-pages-business-02-index-jsx" */),
  "component---src-pages-contact-thanks-index-jsx": () => import("./../../../src/pages/contact/thanks/index.jsx" /* webpackChunkName: "component---src-pages-contact-thanks-index-jsx" */),
  "component---src-pages-event-index-jsx": () => import("./../../../src/pages/event/index.jsx" /* webpackChunkName: "component---src-pages-event-index-jsx" */),
  "component---src-pages-first-index-jsx": () => import("./../../../src/pages/first/index.jsx" /* webpackChunkName: "component---src-pages-first-index-jsx" */),
  "component---src-pages-kids-top-index-jsx": () => import("./../../../src/pages/kids_top/index.jsx" /* webpackChunkName: "component---src-pages-kids-top-index-jsx" */),
  "component---src-pages-life-index-jsx": () => import("./../../../src/pages/life/index.jsx" /* webpackChunkName: "component---src-pages-life-index-jsx" */),
  "component---src-pages-program-01-index-jsx": () => import("./../../../src/pages/program01/index.jsx" /* webpackChunkName: "component---src-pages-program-01-index-jsx" */),
  "component---src-pages-program-02-index-jsx": () => import("./../../../src/pages/program02/index.jsx" /* webpackChunkName: "component---src-pages-program-02-index-jsx" */),
  "component---src-pages-recreation-01-index-jsx": () => import("./../../../src/pages/recreation01/index.jsx" /* webpackChunkName: "component---src-pages-recreation-01-index-jsx" */),
  "component---src-pages-sdgs-index-jsx": () => import("./../../../src/pages/sdgs/index.jsx" /* webpackChunkName: "component---src-pages-sdgs-index-jsx" */),
  "component---src-pages-sorahe-top-index-jsx": () => import("./../../../src/pages/sorahe_top/index.jsx" /* webpackChunkName: "component---src-pages-sorahe-top-index-jsx" */),
  "component---src-templates-0-article-index-jsx": () => import("./../../../src/templates/0_article/index.jsx" /* webpackChunkName: "component---src-templates-0-article-index-jsx" */),
  "component---src-templates-1-top-index-jsx": () => import("./../../../src/templates/1_top/index.jsx" /* webpackChunkName: "component---src-templates-1-top-index-jsx" */),
  "component---src-templates-2-about-index-jsx": () => import("./../../../src/templates/2_about/index.jsx" /* webpackChunkName: "component---src-templates-2-about-index-jsx" */),
  "component---src-templates-4-blog-index-jsx": () => import("./../../../src/templates/4_blog/index.jsx" /* webpackChunkName: "component---src-templates-4-blog-index-jsx" */),
  "component---src-templates-5-privacy-index-jsx": () => import("./../../../src/templates/5_privacy/index.jsx" /* webpackChunkName: "component---src-templates-5-privacy-index-jsx" */),
  "component---src-templates-6-contact-index-jsx": () => import("./../../../src/templates/6_contact/index.jsx" /* webpackChunkName: "component---src-templates-6-contact-index-jsx" */)
}

